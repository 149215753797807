import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaRegPaperPlane } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { addNewMsg } from '../05_redux/ChatSlice';
import store, { RootState } from '../05_redux/store';
import { MsgType, UserType } from '../app/types';
import { IonButton, IonCard, IonInput, IonList, IonItem, IonContent, IonPage } from '@ionic/react';


const Chat = ({selectedKid, slectedParent}:Props) =>{
  const dispatch = store.dispatch;
  const [newMsg, setNewMsg] = useState("");
  const user: UserType = useSelector((root: RootState) => root.statusSlice.status.user as UserType);
  const conversations = useSelector((root:RootState)=>root.chatSlice.chat.conversations);
  const count = useSelector((root:RootState)=>root.chatSlice.chat.count);
  const particianetId = user.therapist?.objectId || selectedKid?.objectId || slectedParent;
  
  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight)
  }, [count])

  const newMessage:MsgType = {
    message: newMsg,
    sender:{"__type":"Pointer",className:"_User", objectId:user.objectId},
    reciver:{"__type":"Pointer",className:"_User", objectId:particianetId}
  }

  return(
    <>
      <IonPage>
        <IonContent>
          <section className='chat-section'>
            <IonList style={{ paddingBottom: "60px" }}>
              {conversations[particianetId]?.map((msg, idx) => {
                const isSender = (msg.sender?.objectId || msg.get('sender').id) === user.objectId;
                const isReciver = (msg.reciver?.objectId || msg.get('reciver').id) === user.objectId;
                return (
                  <IonItem key={idx} lines="none" >
                    <IonCard className={classNames({ "post": true, "chatMsg-sender": isSender, "chatMsg-reciver": isReciver })} style={{ margin: "5px 0" }}>
                      <div style={{padding: "3px"}}>
                        <p className='chat-msg'>{msg.message || msg.get('message')}</p>
                        <p className='chat-date'>{moment(msg.createdAt || msg.get('createdAt')).format("YYYY-MM-DD HH:mm")}</p>
                      </div>
                    </IonCard>
                  </IonItem>
                )
              })}
            </IonList>
          </section>
          <div className='chat-send'>
            <IonInput
              style={{ width: "80%" }}
              value={newMsg}
              onIonChange={(event) => { setNewMsg(event.detail.value!) }}
              placeholder='Type a message'
            />
            <IonButton size='default' onClick={() => { dispatch(addNewMsg(newMessage)) }}>
              <FaRegPaperPlane />
            </IonButton>
          </div>
        </IonContent>
      </IonPage>
    </>
  )
}

interface Props{
  selectedKid: UserType;
  slectedParent: string;
}

export default Chat;
