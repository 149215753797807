import React, { useEffect, useState } from "react";
import { IonFab, IonFabButton, IonIcon, IonButton } from "@ionic/react";
import {
  home as homeIcon,
  settings as settingsIcon,
  add as addIcon,
} from "ionicons/icons";
import Wall from "./Wall";
import HomeWork from "./HomeWorks";
import { useNavigate } from "react-router-dom";
import classes from "./Main.module.scss";

const Main = ({ idx }: Props) => {
  const [activeTab, setActiveTab] = useState<"wall" | "homework">(
    idx === 0 ? "wall" : "homework"
  );
  const navigate = useNavigate();

  const addBtnAction = () => {
    if (activeTab === "homework") {
      navigate("/newhomework");
    } else {
      navigate("/newpost");
    }
  };

  useEffect(() => {
    setActiveTab(idx === 0 ? "wall" : "homework");
  }, [idx]);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.content}>
        {activeTab === "wall" && <Wall />}
        {activeTab === "homework" && <HomeWork />}
      </div>

      <div className={classes.tabButtons}>
        <IonFab
          className={classes.addButton}
          vertical="bottom"
          horizontal="end"
          slot="fixed"
        >
          <IonFabButton id="goto-add-action-btn" onClick={addBtnAction}>
            <IonIcon icon={addIcon} />
          </IonFabButton>
        </IonFab>
        <IonButton
          onClick={() => setActiveTab("wall")}
          className={activeTab === "wall" ? classes.active : ""}
        >
          <IonIcon icon={homeIcon} />
        </IonButton>
        <IonButton
          onClick={() => setActiveTab("homework")}
          className={activeTab === "homework" ? classes.active : ""}
        >
          <IonIcon icon={settingsIcon} />
        </IonButton>
      </div>
    </div>
  );
};

interface Props {
  idx: number;
}

export default Main;
