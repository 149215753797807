import React, { useCallback, useMemo, useState } from "react";
import classes from "./Emotion.module.scss";
import { useTranslation } from "react-i18next";
import { IonButton, IonCol, IonRow, IonSegment, IonSegmentButton, IonLabel } from "@ionic/react";

const Emotion = ({ emotions, setEmotions }: Props) => {
    const { t } = useTranslation();
    const [idx, setIdx] = useState(0);

    const emotionsList = useMemo(
        () => ({
            pleasant: ["gratitude", "joy", "satisfaction", "peace", "love", "pride"],
            unpleasant: [
                "anger",
                "sadness",
                "shame",
                "fear",
                "jealousy",
                "irritation",
                "anxiety",
            ],
        }),
        []
    );

    const getEmotionLabel = (e) => {
        return e.split(":")[0];
    };

    const toggleEmotion = useCallback(
        (e: string) => {
            let newEmotions: string[] = [];
            if (emotions.map((ee) => getEmotionLabel(ee)).includes(e)) {
                newEmotions = emotions.filter((ee) => getEmotionLabel(ee) !== e);
            } else if (emotions.length < 3) {
                newEmotions = [...emotions];
                newEmotions.push(e + ":50");
            } else {
                newEmotions = [...emotions];
            }
            setEmotions(newEmotions);
        },
        [emotions, setEmotions]
    );

    return (
        <div className={classes.CommentContainer}>
            <IonRow className={classes.EmotionTypeContainer}>
                <IonRow className={classes.SegmentContainer}>
                    <IonSegment value={idx.toString()} onIonChange={(e) => setIdx(parseInt(e.detail.value))} className={classes.ToggleSegment}>
                        <IonSegmentButton value="0" className={idx === 0 ? classes.ToggleSegmentButtonActive : classes.ToggleSegmentButton}>
                            <IonLabel>{t("emotions.pleasant").toUpperCase()}</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="1" className={idx === 1 ? classes.ToggleSegmentButtonActive : classes.ToggleSegmentButton}>
                            <IonLabel>{t("emotions.unpleasant").toUpperCase()}</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonRow>
            </IonRow>

            {idx === 0 && (
                <IonRow className={classes.EmotionSelectContainer}>
                    {emotionsList.pleasant.map((e) => {
                        const modifier = emotions
                            .map((ee) => getEmotionLabel(ee))
                            .includes(e)
                            ? "solid"
                            : "outline";
                        return (
                            <IonCol className={classes.Emotion} key={e}>
                                <IonButton
                                    className={classes.EmotionBtn}
                                    fill={modifier}
                                    onClick={() => toggleEmotion(e)}
                                >
                                    {t("emotions." + getEmotionLabel(e)).toUpperCase()}
                                </IonButton>
                            </IonCol>
                        );
                    })}
                </IonRow>
            )}
            {idx === 1 && (
                <IonRow className={classes.EmotionSelectContainer}>
                    {emotionsList.unpleasant.map((e) => {
                        const modifier = emotions
                            .map((ee) => getEmotionLabel(ee))
                            .includes(e)
                            ? "solid"
                            : "outline";
                        return (
                            <IonCol className={classes.Emotion} key={e}>
                                <IonButton
                                    className={classes.EmotionBtn}
                                    fill={modifier}
                                    onClick={() => toggleEmotion(e)}
                                >
                                    {t("emotions." + getEmotionLabel(e)).toUpperCase()}
                                </IonButton>
                            </IonCol>
                        );
                    })}
                </IonRow>
            )}

            {emotions.length > 0 && (
                <div className={classes.SelectedEmotions}>
                    <strong>{t("Wybrano")}:</strong> {emotions.map(e => t("emotions." + getEmotionLabel(e))).join(", ")}
                </div>
            )}
        </div>
    );
};

interface Props {
    emotions: string[];
    setEmotions: Function;
}

export default Emotion;