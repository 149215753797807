import React from "react";
import classes from "./Emotion.module.scss";
import { useTranslation } from "react-i18next";
import { IonRow, IonRange, IonIcon } from "@ionic/react";
import { flameOutline, sadOutline } from 'ionicons/icons';

const EmotionRange = ({ emotions, setEmotionValue, getEmotionLabel, getEmotionValue }: Props) => {
    const { t } = useTranslation();

    return (
        <div className={classes.EmotionRangeContainer}>
            {emotions.length === 0 ? (
                <div className={classes.NoDataContainer}>
                    <IonIcon icon={sadOutline} size="large" className={classes.SadIcon} />
                    <div>{t("Brak danych do wyświetlenia!")}</div>
                </div>
            ) : (
                emotions.map((e) => (
                    <IonRow className={classes.EmotionRangeContainer} key={e}>
                        <div>
                            {t("emotions." + getEmotionLabel(e))} : {getEmotionValue(e)}%
                        </div>
                        <IonRange
                            className={classes.EmotionRage}
                            value={getEmotionValue(e)}
                            onIonChange={(event) =>
                                setEmotionValue(e, parseInt(event.detail.value.toString()))
                            }
                        />
                        <IonRow className={classes.FlameContainer}>
                            <IonIcon icon={flameOutline} size="small" className={classes.LeftIcon} />
                            <IonIcon icon={flameOutline} size="large" className={classes.RightIcon} />
                        </IonRow>
                    </IonRow>
                ))
            )}
        </div>
    );
};

interface Props {
    emotions: string[];
    setEmotionValue: (e: string, value: number) => void;
    getEmotionLabel: (e: string) => string;
    getEmotionValue: (e: string) => number;
}

export default EmotionRange;