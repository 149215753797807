import React from 'react';
import classes from './FeelLikeSelector.module.scss';
import { FaRegAngry, FaRegGrinStars, FaRegMeh } from 'react-icons/fa';

const FeelLikeSelector = ({ feelLike, setFeelLike }: Props) => {
    const options = [
        <FaRegGrinStars />,
        <FaRegMeh />,
        <FaRegAngry />
    ];

    return (
        <div className={classes.feellikeselectorContainer}>
            <div className={classes.feellikeselectorWrapper} style={{ transform: `translateX(calc(50% - ${feelLike * 190}px - 90px))` }}>
                {options.map((o, i) => (
                    <span
                        key={i}
                        className={feelLike === i ? classes.feellikeselectorOptionCheck : classes.feellikeselectorOption}
                        onClick={() => setFeelLike(i)}
                    >
                        {o}
                    </span>
                ))}
            </div>
        </div>
    );
};

interface Props {
    feelLike: number;
    setFeelLike: Function;
}

export default FeelLikeSelector;