import React, { useEffect } from "react";
import { IonButton, IonList, IonItem, IonLabel } from "@ionic/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { requestAvailableKids, setSelectedKid } from "../05_redux/StatusSlice";
import store, { RootState } from "../05_redux/store";

const SelectKid = () => {
  const dispatch = store.dispatch;
  const navigate = useNavigate();
  let availableKids: any[] = useSelector(
    (root: RootState) => root.statusSlice.status.availableKids
  ).filter((k) => k.type === "kid");

  useEffect(() => {
    dispatch(requestAvailableKids());
  }, [dispatch]);

  const gotoKid = (kid) => {
    dispatch(setSelectedKid(kid));
    setTimeout(() => {
      navigate("/wall");
    }, 100);
  };

  return (
    <section>
      <IonList>
        {availableKids.map((kid, idx) => (
          <IonItem key={idx} button onClick={() => gotoKid(kid)}>
            <IonLabel>{kid.username}</IonLabel>
            <IonButton slot="end" onClick={() => gotoKid(kid)}>
              Select
            </IonButton>
          </IonItem>
        ))}
      </IonList>
    </section>
  );
};

export default SelectKid;
