import React from 'react';
import classes from './Pagination.module.scss';
import { FaArrowLeft, FaArrowRight, FaRegDotCircle } from 'react-icons/fa';
import { FaRegCircle } from 'react-icons/fa6';
import { IonButton, IonCol, IonRow } from '@ionic/react';

const Pagination = ({ progress, max, setPage, next, back }: Props) => {
  const onClick = (i: number) => {
    setPage(i);
  };

  return (
    <IonRow className={classes.paginationContainer}>
      <IonCol className={classes.WizardPageBottomCol} size="2">
        <IonButton fill="clear" onClick={back}>
          <FaArrowLeft />
        </IonButton>
      </IonCol>
      <IonCol className={classes.WizardPageBottomCol} size="8">
        {Array(max)
          .fill(0)
          .map((_, i) => (
            <span key={i} className={classes.paginationMark} onClick={() => onClick(i)}>
              {i === Math.round((max - 1) * progress) ? <FaRegDotCircle /> : <FaRegCircle />}
            </span>
          ))}
      </IonCol>
      <IonCol className={classes.WizardPageBottomCol} size="2">
        <IonButton fill="clear" onClick={next}>
          <FaArrowRight />
        </IonButton>
      </IonCol>
    </IonRow>
  );
};

interface Props {
  progress: number;
  max: number;
  setPage: (page: number) => void;
  next: () => void;
  back: () => void;
}

export default Pagination;
