import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../05_redux/store";
import { HomeworkEntity } from "../../06_utils/db/entity/homeworkEntity";
import classes from "./HomeworkDetail.module.scss";
import Wave from "../../01_atoms/Wave";
import TopBar from "../../01_atoms/TopBar";
import { FaFlagCheckered, FaPlus } from "react-icons/fa";
import Post from "../Post/Post";
import { useTranslation } from "react-i18next";
import { IonCol, IonPage, IonRow } from "@ionic/react";
import moment from "moment";

const HomeworkDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const homeworks: HomeworkEntity[] = useSelector(
    (root: RootState) => root.homeworkSlice.homeworkStore.homeworks
  );
  const { id } = useParams<{ id: string }>();

  const homework = homeworks.find((h) => h.id === Number(id));

  const back = () => {
    navigate("/homework/");
  };

  const createNewPost = () => {
    navigate("/newpost/" + homework?.id);
  };

  const endHomework = () => {
    navigate("/endhomework/" + homework?.id);
  };

  return (
    <IonPage>
      <TopBar fun={back} title={homework?.title as string} />
      <Wave invert />
      <div className={classes.HomeworkDetail}>
        <div className={classes.HomeworkTitleContainer}>
          <h3 className={classes.HomeworkTitle}>{homework?.title}</h3>
          <div className={`${classes.CornerTooltip}`}>
            {"do " + moment(homework.homeworkDate).format("MM-DD")}
          </div>
        </div>
        <IonRow>
          <IonCol className={classes.HomeworkDetailDescr}>
            {homework?.descr}
          </IonCol>
        </IonRow>
        {!homework?.archived && (
          <div className={classes.HomeworkDetailButtons}>
            <h3>Odrób zadanie:</h3>
            <div className={classes.divider}></div>
            <IonCol className={classes.HomeworkDetailButton}>
              <div
                onClick={endHomework}
                className={classes.actionText}
                style={{
                  cursor:
                    homework?.post?.length === 0 ? "not-allowed" : "pointer",
                  color: homework?.post?.length === 0 ? "#ccc" : "#007bff",
                }}
              >
                <FaFlagCheckered size={16} />
                <span>{t("homeworkDetail.closeHomework")}</span>
              </div>
            </IonCol>
            <IonCol className={classes.HomeworkDetailButton}>
              <div onClick={createNewPost} className={classes.actionText}>
                <FaPlus size={16} />
                <span>{t("homeworkDetail.addPost")}</span>
              </div>
            </IonCol>
          </div>
        )}
        <IonRow className={classes.HomeworkDetailPosts}>
          <IonCol>
            {homework?.post?.length === 0 && t("homeworkDetail.noPosts")}
            {homework?.post?.map((pfh, i) => (
              <Post key={i} post={pfh} editable={false} />
            ))}
          </IonCol>
        </IonRow>
      </div>
    </IonPage>
  );
};

export default HomeworkDetail;
