import React from "react";
import { IonButton, IonCol, IonRow, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import Wave from "../01_atoms/Wave";
import classes from "./WizardPage.module.scss";
import { useTranslation } from "react-i18next";
import Pagination from "../01_atoms/Pagination";
import TopBar from "../01_atoms/TopBar";

const WizardPage = ({
  mainWizardTitle,
  back,
  next,
  setPage,
  save,
  cancel,
  saveEnable,
  wizardProgress,
  wizardMax,
  title,
  children,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Wave invert={false} />
      <TopBar
        fun={cancel}
        title={""}
        icon={<IonIcon icon={closeOutline} />}
        withBackground={false}
      />
      <div className={classes.WizardPageContent}>
        <IonRow>
          <IonCol>
            <h1 className={classes.MainWizardTitle}>{mainWizardTitle}</h1>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <h1 className={classes.Title}>{title}</h1>
            <IonCol className={classes.WizardPageChildren}>{children}</IonCol>
          </IonCol>
        </IonRow>
      </div>
      <div className={classes.WizardPageBottomProgressBar}>
        <Pagination
          progress={wizardProgress}
          max={wizardMax}
          setPage={setPage}
          next={next}
          back={back}
        />
      </div>
      <IonRow className={classes.WizardPageBottom}>
        <IonCol className={classes.WizardPageBottomCol}>
          <IonButton className={classes.WizardPageButton} expand="block" onClick={save} disabled={!saveEnable}>
            {t("btn.save").toUpperCase()}
          </IonButton>
        </IonCol>
      </IonRow>
    </>
  );
};

interface Props {
  back: Function;
  next: Function;
  setPage: Function;
  save: (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
  cancel: Function;
  saveEnable: boolean;
  wizardProgress: number;
  wizardMax: number;
  mainWizardTitle: string,
  title: string;
  children: string | JSX.Element | JSX.Element[];
}

export default WizardPage;
